.web {
  .ant-modal-content {
    position: relative;
    background-color: #00000000 !important;
    border: 0;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  .ant-modal-body {
    padding: 0 !important;
    font-size: 0 !important;
    line-height: 1 !important;
  }

  .space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
}
.Carousel{
    text-align: center;
    justify-content: center;
    margin: auto;
  }
  .slick-dots li.slick-active button {
    width: 8px !important ;
    height: 8px !important;
    background: #fa7a1d !important;
    border-radius: 100% !important;
    border: 2px solid #ffffff !important;
  }
  .slick-dots li button {
    width: 8px !important ;
    height: 8px !important;
    background: #ffffffff !important;
    border-radius: 100% !important;
    opacity: 1 !important,

  }

  /* .slick-dots li {
    width: 23px !important ;
  }
  .slick-dots li.slick-active {
    width: 23px !important ;
  } */
  .ant-carousel .slick-dots-bottom{
    bottom: -2% !important;
  }
  .text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
